import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Kengetallen, OmzetAanwas, ReportFilterBase, Veebalans, VeebalansBeknopt } from '../types/reports';
import { createFilterParams, fetchAuthenticated } from '../utilities';

const QUERY_KEY = ['reports'];

export const useGetVeebalans = (params?: ReportFilterBase): UseQueryResult<Veebalans, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'veebalans', params],
    queryFn: () => fetchAuthenticated<Veebalans>(`/report/veebalans?${createFilterParams({ ...params })}`, undefined, 60 * 1000),
    enabled: !!params,
  });

export const useGetVeebalansBeknopt = (params?: ReportFilterBase): UseQueryResult<VeebalansBeknopt, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'veebalansbeknopt', params],
    queryFn: () => fetchAuthenticated<VeebalansBeknopt>(`/report/veebalansbeknopt?${createFilterParams({ ...params })}`),
    enabled: !!params,
  });

export const useGetOmzetAanwas = (params?: ReportFilterBase): UseQueryResult<OmzetAanwas, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'omzetaanwas', params],
    queryFn: () => fetchAuthenticated<OmzetAanwas>(`/report/omzetaanwas?${createFilterParams({ ...params })}`),
    enabled: !!params,
  });

export const useGetKengetallen = (params?: ReportFilterBase): UseQueryResult<Kengetallen, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'kengetallen', params],
    queryFn: () => fetchAuthenticated<Kengetallen>(`/report/kengetallen?${createFilterParams({ ...params })}`),
    enabled: !!params,
  });
