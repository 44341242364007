import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { ReportFilter } from '@/lib/components/report/reportFilter';
import { useFinancialYearContext } from '@/lib/context';
import { useGetKengetallen } from '@/lib/queries/reports';
import { ReportFilterBase } from '@/lib/types/reports';
import { Flex } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Kengetallen: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const { financialYear } = useFinancialYearContext();

  const [reportFilter, setReportFilter] = useState<ReportFilterBase>();
  const { data, isLoading } = useGetKengetallen(reportFilter);

  const onFilterChange = filter => {
    setReportFilter(filter);
  };

  return (
    <Flex vertical gap="middle" style={{ height: '100%' }}>
      <ReportFilter
        onChange={onFilterChange}
        filterOptions={{ referenceDate: { min: financialYear?.startDate, max: financialYear?.endDate } }}
        initialValues={{ from: financialYear?.startDate, to: financialYear?.endDate }}
      />
      <PageContent title={t('pages.kengetallen')}>{!data && !isLoading ? t('state.pending') : <></>}</PageContent>
    </Flex>
  );
};
